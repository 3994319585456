<template>
  <el-dialog v-model="dialogVisible" title="添加参数" :close-on-click-modal="false" width="514px">
    <div class="dp-f mt-20 mb-30">
      <el-form ref="ruleFormRef" :model="ruleForm" status-icon label-width="90px"
        class="demo-ruleForm w100" label-position="left">
        <el-form-item label="参数名称" prop="parameterName">
          <el-input v-model="ruleForm.parameterName" :maxlength="config.inputMaxlength" show-word-limit
            placeholder="请输入参数名称" />
        </el-form-item>
        <el-form-item label="参数标志" prop="parameterName">
          <el-input v-model="ruleForm.parameterKey" :maxlength="config.inputMaxlength" show-word-limit
            placeholder="请输入参数名称" />
        </el-form-item>
        <!-- <el-form-item label="参数类型" prop="parameterName">
          <el-select v-model="value" placeholder="Select" style="width: 240px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            />
          </el-select>
        </el-form-item> -->

        <!-- <el-form-item label="参数长度" prop="parameterLength">
          <el-input-number placeholder="请输入参数长度" min="0" v-model="ruleForm.parameterLength"></el-input-number>
        </el-form-item> -->
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f jc-c">
        <oabutton class="m-0-at" width="462" height="52" style="border-radius: 4px; font-size: 18px" title="确认"
          CSStype="2" @buttonclick="submitForm">
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits, defineProps } from "vue";
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
//    import { validPhone, validEmail, validPassword } from "@/utils/server/validate.js";
import { validEmail } from "@/utils/server/validate.js";
import { httpToken } from "@/utils/request";
import { pinyin } from 'pinyin-pro';
import qs, { stringify } from "qs";
import verificationCode from "@/components/button/verificationCode.vue"; //验证码组件
const dialogloading = ref(false); // 弹框按钮loading 显示
const dialogVisible = ref(false); //弹框显示
const emit = defineEmits(["Emit"]);
const id = ref(0); //操作的id
const verificationCodeRef = ref(); //验证码组件ref
const state = reactive({});
const ruleFormRef = ref(null); //表单ref
const ruleForm = reactive({
  //from 表单数据
  parameterName: "",//参数名称
  parameterKey: "",//长度
});
// const validatePass = (rule, value, callback) => {
//   if (value === '') {
//     callback(new Error('参数长度不能为空'))
//   } else {
//     if (value >= 2&&value <= 20) {
//       callback()
//     }else{
//       callback(new Error('参数长度为2到20'))
//     }
//   }
// }
// const rules = reactive({
//   //from 表单校验
//   parameterName: [
//     { required: true, message: '请输入参数名称' },
//   ],
//   parameterKey: [
//     { required: true, message: '请输入参数标志' },
//   ],
// });
// 表单重置
let fromDataReset = () => {
  objdata_ret(ruleForm);
};
let show = () => {
  fromDataReset();
  dialogloading.value = false;
  dialogVisible.value = true;
  nextTick(() => {
    unref(ruleFormRef).clearValidate(); //清除表单验证结果
  });
};
// 表单提交
const submitForm = async () => {
  dialogloading.value = true;
  if (ruleForm.parameterKey.length <= 0) {
    handleMessage("请输入参数标志")
    return
  }
  if (ruleForm.parameterName.length <= 0) {
    handleMessage("请输入参数名称")
    return
  }
  emit("Emit", ruleForm)
  fromDataReset()
};
// const addparams = () => {
//   httpToken({
//     method: "post",
//     url: "/admin/template-dynamic-filed/add",
//     data: {
//       parameterName: ruleForm.parameterName,//参数名
//       parameterLength: (ruleForm.parameterLength * 20),//参数长度
//       parameterHeight: '16',//参数高度
//       parameterPinyin: pinyin(ruleForm.parameterName, { toneType: 'none' }),//参数拼音
//     }
//   }).then(res => {
//     dialogVisible.value = false
//     dialogloading.value = false;
//     handleMessage('新增成功', 'success')
//     emit('Emit')
//   })
// }

defineExpose({
  show,
});
</script>
<style lang="scss" scoped>
@import "@/styles/general/element/settingFrom.scss"; //element 样式重置

// ::v-deep .el-form-item__label {
//   display: none;
// }
</style>